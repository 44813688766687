.lowercase 			{text-transform:lowercase; 	}
.uppercase 			{text-transform:uppercase; 	}
.transparent		{background:transparent !important;}
.spacer_06 			{height:6px;}
.spacer_12 			{height:12px;}
.spacer_18 			{height:18px;}
.spacer_24 			{height:24px;}
.spacer_48 			{height:48px;}
.pad-12				{padding:12px}
.pad-24				{padding:24px}
.pad-48				{padding:48px}
.hideAnim 			{visibility: hidden;}
.showAnim 			{visibility: visible;}
.shadow 			{-webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, .15);-moz-box-shadow: 0 4px 4px rgba(0, 0, 0, .15);box-shadow: 0 4px 4px rgba(0, 0, 0, .15);}
.innershadow 		{-webkit-box-shadow: inset 0px 4px 4px rgba(0, 0, 0, .15);-moz-box-shadow: inset 0px 4px 4px rgba(0, 0, 0, .15);box-shadow: inset 0px 4px 4px rgba(0,0,0,0.15)}
.glow				{box-shadow: 0 4px 20px 0 rgba(153, 153, 153, 0.7), 0 6px 20px 0 rgba(153, 153, 153, 0.005);}
.stroke 			{-webkit-text-stroke-width: 0.02em;-webkit-text-fill-color: transparent;-webkit-background-clip: text;background-repeat: no-repeat;  transition: background-size .5s cubic-bezier(0.67, 0.01, 0.15, 0.98);}
.fw-lgt				{font-weight:200}
.fw-reg				{font-weight:400}
.fw-med				{font-weight:600}
.fw-bol				{font-weight:800}

.fs-7 				{font-size: .9rem!important;}
.fs-8 				{font-size: .8rem!important;}
.fs-9               {font-size: 44px!important;}



.bg-black 		{background-color:#000000}
.bg-white 		{background-color:#ffffff}
.bg-acc-transp  {--bs-accordion-bg:transparent!important;}
.bg-01 			{background-color:#8498AC}
.bg-02 			{background-color:#466582}
.bg-03 			{background-color:#F8D16C}
.bg-04 			{background-color:#F2F2F2}
.bg-05 			{background-color:#545253}
.bg-06 			{background-color:#363636}
.bg-07 			{background-color:#F3F9FA}



.tc-01 		{color:#8498AC!important}
.tc-02 		{color:#466582!important}
.tc-03 		{color:#F8D16C!important}
.tc-04 		{color:#F2F2F2!important}
.tc-05 		{color:#545253!important}
.tc-06 		{color:#363636!important}
.tc-07 		{color:#cccccc!important}
.tc-white	{color:#ffffff!important}
.tc-black	{color:#000000!important}


.aside-clock {
	@include aside-close {
		display: none;
	}
}

.aside-search {
	@include aside-close {
		display: none;
	}
}

.structure-card {
	background-color: #F2F2F2;
	border-color: #8c8589;
	border: 1px solid;
}
