
@mixin admin-mode($withOutParent: false) {
	@if $withOutParent {
		@at-root [layoutType='admin'] {
			@content;
		}
	} @else {
		@at-root [layoutType='admin'] & {
			@content;
		}
	}
}
